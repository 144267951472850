@tailwind base;
@tailwind components;
@tailwind utilities;

/* Android(Roboto)和web字体(Fredoka) */
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {
  @font-face {
    font-family: 'GreycliffCF';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://cdn1.just4fun.sg/fonts/GreycliffCF/GreycliffCF-Regular.otf') format('opentype');
  }

  html {
    @apply bg-background;
  }

  body {
    @apply text-sm text-fontcolor-500 lg:text-base;
  }

  /* 安卓端 */
  html[data-client='android'] {
    font-family: roboto, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    /* 自定义字租 */
    --custom-bold: 500;
  }
  html[data-client='android'] h1,
  html[data-client='android'] h2,
  html[data-client='android'] h3,
  html[data-client='android'] h4,
  html[data-client='android'] h5,
  html[data-client='android'] h6 {
    @apply font-medium;
  }
  html[data-client='android'] h1 {
    @apply text-2xl;
  }
  html[data-client='android'] h2 {
    @apply text-xl;
  }
  html[data-client='android'] h3 {
    @apply text-base;
  }

  /* ios端 */
  html[data-client='iOS'] {
    font-family:
      greycliffCF ui-sans-serif,
      system-ui,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    /* 自定义字租 */
    --custom-bold: 700;
  }
  html[data-client='iOS'] h1,
  html[data-client='iOS'] h2,
  html[data-client='iOS'] h3,
  html[data-client='iOS'] h4,
  html[data-client='iOS'] h5,
  html[data-client='iOS'] h6 {
    @apply font-bold;
  }
  html[data-client='iOS'] h1 {
    @apply text-2xl;
  }
  html[data-client='iOS'] h2 {
    @apply text-xl;
  }
  html[data-client='iOS'] h3 {
    @apply text-base;
  }

  /* web端 */
  html[data-client='web'] {
    font-family: fredoka, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    /* 自定义字租 */
    --custom-bold: 500;
  }
  html[data-client='iOS'] h1,
  html[data-client='iOS'] h2,
  html[data-client='iOS'] h3,
  html[data-client='iOS'] h4,
  html[data-client='iOS'] h5,
  html[data-client='iOS'] h6 {
    @apply font-medium;
  }
  html[data-client='iOS'] h1 {
    @apply text-2xl;
  }
  html[data-client='iOS'] h2 {
    @apply text-xl;
  }
  html[data-client='iOS'] h3 {
    @apply text-base;
  }

  html[data-theme='candlelight'] {
    /* 背景色 */
    --color-background: 255 255 255;
    /* 主色调 */
    --color-primary: 255 219 13;
    /* 主色调下文字颜色 */
    --color-primary-text: 0 0 0;
    /* 主色调-轻 */
    --color-primary-light: 247 242 216;
    /* 主色调-超轻 */
    --color-primary-extralight: 246 243 229;
    /* 主色调-重 */
    --color-primary-dark: 234 138 0;

    --color-btn-primary: 255 219 13;
    --color-btn-primary-text: 0 0 0;
    --color-btn-secondary: 247 242 216;
    --color-btn-secondary-text: 255 219 13;

    --color-text-100: 245 245 246;
    --color-text-200: 225 226 229;
    --color-text-300: 195 196 202;
    --color-text-400: 136 138 151;
    --color-text-500: 57 60 83;
    --color-text-600: 0 34 49;
  }

  html[data-theme='regular'],
  html[data-theme='navy-blue'] {
    /* 背景色 */
    --color-background: 255 255 255;
    /* 主色调 */
    --color-primary: 3 169 245;
    /* 主色调下文字颜色 */
    --color-primary-text: 255 255 255;
    /* 主色调-轻 */
    --color-primary-light: 211 237 251;
    /* 主色调-超轻 */
    --color-primary-extralight: 232 246 253;
    /* 主色调-重 */
    --color-primary-dark: 2 131 209;
    /*头部背景色*/
    --color-header-bg: 255 255 255;

    --color-btn-primary: 3 169 245;
    --color-btn-primary-text: 255 255 255;
    --color-btn-secondary: 211 237 251;
    --color-btn-secondary-text: 3 169 245;

    --color-text-100: 245 245 246;
    --color-text-200: 225 226 229;
    --color-text-300: 195 196 202;
    --color-text-400: 136 138 151;
    --color-text-500: 57 60 83;
    --color-text-600: 0 34 49;
  }

  html[data-theme='orange'] {
    /* 背景色 */
    --color-background: 255 255 255;
    /* 主色调 */
    --color-primary: 250 156 42;
    /* 主色调下文字颜色 */
    --color-primary-text: 255 255 255;
    /* 主色调-轻 */
    --color-primary-light: 254 236 212;
    /* 主色调-超轻 */
    --color-primary-extralight: 254 243 232;
    /* 主色调-重 */
    --color-primary-dark: 215 110 0;

    --color-btn-primary: 250 156 42;
    --color-btn-primary-text: 255 255 255;
    --color-btn-secondary: 254 236 212;
    --color-btn-secondary-text: 250 156 42;

    --color-text-100: 245 245 246;
    --color-text-200: 225 226 229;
    --color-text-300: 195 196 202;
    --color-text-400: 136 138 151;
    --color-text-500: 57 60 83;
    --color-text-600: 0 34 49;
  }

  html[data-theme='lavender'] {
    /* 背景色 */
    --color-background: 255 255 255;
    /* 主色调 */
    --color-primary: 151 121 207;
    /* 主色调下文字颜色 */
    --color-primary-text: 255 255 255;
    /* 主色调-轻 */
    --color-primary-light: 242 233 254;
    /* 主色调-超轻 */
    --color-primary-extralight: 241 232 254;
    /* 主色调-重 */
    --color-primary-dark: 215 110 0;

    --color-btn-primary: 151 121 207;
    --color-btn-primary-text: 255 255 255;
    --color-btn-secondary: 242 233 254;
    --color-btn-secondary-text: 51 62 80;

    --color-text-100: 245 245 246;
    --color-text-200: 225 226 229;
    --color-text-300: 195 196 202;
    --color-text-400: 136 138 151;
    --color-text-500: 57 60 83;
    --color-text-600: 0 34 49;
  }

  html[data-theme='night-blue'] {
    /* 背景色 */
    --color-background: 0 27 43;
    /* 主色调 */
    --color-primary: 0 27 43;
    /* 主色调下文字颜色 */
    --color-primary-text: 255 255 255;
    /* 主色调-轻 */
    --color-primary-light: 47 78 105;
    /* 主色调-超轻 */
    --color-primary-extralight: 91 118 139;
    /* 主色调-重 */
    --color-primary-dark: 110 211 255;

    --color-btn-primary: 110 211 255;
    --color-btn-primary-text: 255 255 255;
    --color-btn-secondary: 232 246 253;
    --color-btn-secondary-text: 0 27 43;

    --color-text-100: 245 245 246;
    --color-text-200: 225 226 229;
    --color-text-300: 195 196 202;
    --color-text-400: 136 138 151;
    --color-text-500: 237 239 240;
    --color-text-600: 255 255 255;
  }

  html[data-theme='dark'] {
    /* 背景色 */
    --color-background: 18 18 18;
    /* 主色调 */
    --color-primary: 18 18 18;
    /* 主色调下文字颜色 */
    --color-primary-text: 255 255 255;
    /* 主色调-轻 */
    --color-primary-light: 44 44 46;
    /* 主色调-超轻 */
    --color-primary-extralight: 44 44 46;
    /* 主色调-重 */
    --color-primary-dark: 3 169 245;

    --color-btn-primary: 3 169 245;
    --color-btn-primary-text: 255 255 255;
    --color-btn-secondary: 232 246 253;
    --color-btn-secondary-text: 51 62 80;

    --color-text-100: 245 245 246;
    --color-text-200: 225 226 229;
    --color-text-300: 195 196 202;
    --color-text-400: 136 138 151;
    --color-text-500: 255 255 255;
    --color-text-600: 255 255 255;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #aaadbe transparent;
    @apply select-none xl:select-auto;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background: #aaadbe;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

@layer components {
  .scrollbar {
    scrollbar-width: none;
  }
  .scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scrollbar:hover {
    scrollbar-width: thin;
  }
  .scrollbar:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .article h1,
  .article h2,
  .article h3,
  .article h4,
  .article h5 {
    @apply mb-1 font-semibold;
  }

  .article h1 {
    @apply mb-3 text-center text-lg;
  }

  .article p {
    @apply mb-3 text-sm;
  }

  /* google ad */
  ins.adsbygoogle a {
    display: none;
  }

  ins.adsbygoogle a img {
    width: 100%;
    height: 100%;
  }

  ins.adsbygoogle[data-ad-status='unfilled'] a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  /* 广告尺寸 */
  .home-m-1,
  .game-m-1 {
    @apply min-h-[240px] w-full;
  }
  .home-1 {
    @apply h-[220px] w-full;
  }
  .slide-1 {
    @apply min-h-[600px];
  }
  .game-1 {
    @apply aspect-square;
  }

  html[data-theme='candlelight'] .cus_header,
  html[data-theme='regular'] .cus_header,
  html[data-theme='navy-blue'] .cus_header,
  html[data-theme='orange'] .cus_header,
  html[data-theme='lavender'] .cus_header,
  html[data-theme='night-blue'] .cus_header,
  html[data-theme='dark'] .cus_header {
    @apply border-b border-gray-200 bg-primary;
  }
  html[data-theme='candlelight'] .cus_header a,
  html[data-theme='regular'] .cus_header a,
  html[data-theme='navy-blue'] .cus_header a,
  html[data-theme='orange'] .cus_header a,
  html[data-theme='lavender'] .cus_header a,
  html[data-theme='night-blue'] .cus_header a,
  html[data-theme='dark'] .cus_header a {
    @apply text-primary-text;
  }
  html[data-theme='candlelight'] .cus_header svg,
  html[data-theme='regular'] .cus_header svg,
  html[data-theme='navy-blue'] .cus_header svg,
  html[data-theme='orange'] .cus_header svg,
  html[data-theme='lavender'] .cus_header svg,
  html[data-theme='night-blue'] .cus_header svg,
  html[data-theme='dark'] .cus_header svg {
    @apply fill-primary-text;
  }
  html[data-theme='candlelight'] .cus_header .cus_header-search,
  html[data-theme='regular'] .cus_header .cus_header-search,
  html[data-theme='navy-blue'] .cus_header .cus_header-search,
  html[data-theme='orange'] .cus_header .cus_header-search,
  html[data-theme='lavender'] .cus_header .cus_header-search,
  html[data-theme='night-blue'] .cus_header .cus_header-search,
  html[data-theme='dark'] .cus_header .cus_header-search {
    @apply bg-primary sm:bg-inherit;
  }
  html[data-theme='candlelight'] .cus_header .cus_header-input,
  html[data-theme='regular'] .cus_header .cus_header-input,
  html[data-theme='navy-blue'] .cus_header .cus_header-input,
  html[data-theme='orange'] .cus_header .cus_header-input,
  html[data-theme='lavender'] .cus_header .cus_header-input {
    @apply border-none bg-[#f5f5f6];
  }

  html[data-theme='night-blue'] .cus_header .cus_header-input {
    @apply border-none bg-[#1c3245] text-primary-text;
  }
  html[data-theme='night-blue'] .cus_header .cus_header-input input {
    @apply caret-[#EDEFF0];
  }
  html[data-theme='dark'] .cus_header .cus_header-input {
    @apply border-none bg-[#1e1e1e] text-primary-text;
  }
  html[data-theme='dark'] .cus_header .cus_header-input input {
    @apply caret-white;
  }
  html[data-theme='night-blue'] .cus_currency-list,
  html[data-theme='dark'] .cus_currency-list {
    @apply -mt-sm bg-primary-light py-md;
  }
  html[data-theme='night-blue'] .cus_menu-icon,
  html[data-theme='dark'] .cus_menu-icon {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(287deg) brightness(106%) contrast(101%);
  }

  /*---*/
  html[data-theme='candlelight'][data-client='android'] .cus_header,
  html[data-theme='candlelight'][data-client='iOS'] .cus_header,
  html[data-theme='regular'][data-client='android'] .cus_header,
  html[data-theme='regular'][data-client='iOS'] .cus_header,
  html[data-theme='navy-blue'][data-client='android'] .cus_header,
  html[data-theme='navy-blue'][data-client='iOS'] .cus_header,
  html[data-theme='orange'][data-client='android'] .cus_header,
  html[data-theme='orange'][data-client='iOS'] .cus_header,
  html[data-theme='lavender'][data-client='android'] .cus_header,
  html[data-theme='lavender'][data-client='iOS'] .cus_header {
    @apply border-b border-gray-200 bg-white;
  }

  html[data-theme='candlelight'][data-client='android'] .cus_header a,
  html[data-theme='candlelight'][data-client='iOS'] .cus_header a,
  html[data-theme='regular'][data-client='android'] .cus_header a,
  html[data-theme='regular'][data-client='iOS'] .cus_header a,
  html[data-theme='navy-blue'][data-client='android'] .cus_header a,
  html[data-theme='navy-blue'][data-client='iOS'] .cus_header a,
  html[data-theme='orange'][data-client='android'] .cus_header a,
  html[data-theme='orange'][data-client='iOS'] .cus_header a,
  html[data-theme='lavender'][data-client='android'] .cus_header a,
  html[data-theme='lavender'][data-client='iOS'] .cus_header a {
    @apply text-fontcolor-500;
  }

  html[data-theme='candlelight'][data-client='android'] .cus_header svg,
  html[data-theme='regular'][data-client='android'] .cus_header svg,
  html[data-theme='navy-blue'][data-client='android'] .cus_header svg,
  html[data-theme='orange'][data-client='android'] .cus_header svg,
  html[data-theme='lavender'][data-client='android'] .cus_header svg {
    @apply fill-fontcolor-500;
  }

  html[data-theme='candlelight'][data-client='iOS'] .cus_header svg,
  html[data-theme='regular'][data-client='iOS'] .cus_header svg,
  html[data-theme='navy-blue'][data-client='iOS'] .cus_header svg,
  html[data-theme='orange'][data-client='iOS'] .cus_header svg,
  html[data-theme='lavender'][data-client='iOS'] .cus_header svg {
    @apply fill-primary;
  }

  html[data-theme='candlelight'][data-client='android'] .cus_header .cus_header-search,
  html[data-theme='candlelight'][data-client='iOS'] .cus_header .cus_header-search,
  html[data-theme='regular'][data-client='android'] .cus_header .cus_header-search,
  html[data-theme='regular'][data-client='iOS'] .cus_header .cus_header-search,
  html[data-theme='navy-blue'][data-client='android'] .cus_header .cus_header-search,
  html[data-theme='navy-blue'][data-client='iOS'] .cus_header .cus_header-search,
  html[data-theme='orange'][data-client='android'] .cus_header .cus_header-search,
  html[data-theme='orange'][data-client='iOS'] .cus_header .cus_header-search,
  html[data-theme='lavender'][data-client='android'] .cus_header .cus_header-search,
  html[data-theme='lavender'][data-client='iOS'] .cus_header .cus_header-search {
    @apply bg-white;
  }

  html[data-theme='candlelight'][data-client='iOS'] .cus_header .cus_header-search svg,
  html[data-theme='regular'][data-client='iOS'] .cus_header .cus_header-search svg,
  html[data-theme='navy-blue'][data-client='iOS'] .cus_header .cus_header-search svg,
  html[data-theme='orange'][data-client='iOS'] .cus_header .cus_header-search svg,
  html[data-theme='lavender'][data-client='iOS'] .cus_header .cus_header-search svg {
    @apply fill-fontcolor-500;
  }
}
